@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap');

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif;

  &:before {
    content: '';
    content: "";
    width: 100%;
    height: 100vh;
    background-color: #dad5d5;
    position: fixed;
    left: 0;
    top: 0;
    z-index: -1;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.text-shadow {
  text-shadow: 0px 2px 0px rgb(0 0 0 / 30%);
}

.adjacent-post {
  & .arrow-btn {
    transition: width 300ms ease;
    width: 50px;
  }

  &:hover {
    & .arrow-btn {
      width: 60px;
    }
  }
}

.react-multi-carousel-list {
  & .arrow-btn {
    transition: width 300ms ease;
    width: 50px;

    &:hover {
      width: 60px;
    }
  }

}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.scrolling {
  animation: scrollUp 20s ease-in-out infinite normal;
  animation-iteration-count: infinite;
}



@keyframes scrollUp {
  from {
    -webkit-transform: translateY(20);
    transform: translateY(20);
  }

  to {
    -webkit-transform: translateY(-70%);
    transform: translateY(-70%);
  }
}

.con {
  height: auto;
  overflow: hidden;
}